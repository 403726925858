import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const OakwoodWomensCentreLandingPage = () => (
  <PhysicianLandingPage
    physician="Oakwood Women's Centre"
    institution="Oakwood Women's Centre"
    referralCode="OAKWOOD"
    physicianURL="https://austinobgyn.womenshealthtx.com/oakwood-womens-centre/"
  />
)

export default OakwoodWomensCentreLandingPage
